export const usePageSeoMeta = async ({
  keepLocale = false,
  withErrorHandling = false,
} = {}) => {
  // const { path } = useRoute();
  // useRoute() here returns wrong route on spa (previous)
  // https://github.com/nuxt/nuxt/issues/21340
  // https://github.com/nuxt/nuxt/issues/28804
  const route = useRouter().currentRoute.value;

  const { locale } = useI18n();
  const cmsStore = useCmsStore();

  const formattedRoutePath = computed(() => {
    if (keepLocale) return route.path;
    else return route.path.replace(new RegExp(`^/${locale.value}`), '');
  });

  // Request
  const { data: pageData, error: pageError } = await useAsyncData(
    formattedRoutePath.value,
    () =>
      cmsStore.fetchPage({
        path: formattedRoutePath.value,
        withLocalePrefix: keepLocale,
      }),
    { default: () => ({}) },
  );

  // Error handling
  if (withErrorHandling && pageError.value) {
    const { statusCode, statusMessage } = pageError.value;
    throw createError({ statusCode, statusMessage, fatal: true });
  }

  const url = useRequestURL();
  const defaultCanonical = `${url?.origin}${url?.pathname?.replace(new RegExp(`^/${locale.value}`), '')}`;

  useSeo({
    title: () =>
      !pageError.value && pageData.value?.meta?.title
        ? pageData.value.meta.title
        : 'Rail Monsters | Search, Compare and Buy Train Tickets Online',
    description: () =>
      !pageError.value && pageData.value?.meta?.description
        ? pageData.value.meta.description
        : 'Rail Monsters - Your one-stop destination to buy wordlwide train tickets online ✓ Save with our discounts ✓ Search & Compare Train Tickets ✓ Get the app for booking',
    image: () =>
      !pageError.value ? pageData.value?.meta?.image?.original : '',
    // keywords: () => (!pageError.value ? pageData.value?.meta?.keywords : ''),
    robots: () => (!pageError.value ? pageData.value?.meta?.robots : ''),
    canonical: () =>
      !pageError.value && pageData.value?.meta?.canonical
        ? pageData.value.meta.canonical
        : defaultCanonical,
    locale: () => (!pageError.value ? locale.value : ''),
  });

  return pageData;
};

import { pageAdapter } from '@/adapters/page';
import { I18N_DEFAULT_LOCALE, LANGUAGE_KEYS } from '@/constants/languages';

export const useCmsStore = defineStore({
  id: 'cms',
  state: () => ({
    translations: cloneObject(LANGUAGE_KEYS),
  }),
  actions: {
    fetchPage({ path, withLocalePrefix = false }) {
      const { locale } = useI18n();

      if (withLocalePrefix && locale.value === I18N_DEFAULT_LOCALE) {
        path = `/${I18N_DEFAULT_LOCALE}${path}`;
      }

      return useRequest(`/pages/path${path}`).then(pageAdapter);
    },
    fetchTranslations(locale) {
      return useRequest('/entities/key/ui', {
        headers: { 'Accept-Language': locale },
      }).then(({ data }) => {
        this.translations[locale] = data;

        return data;
      });
    },
  },
});

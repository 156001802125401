import { accountLoginAdapter, accountAdapter } from '@/adapters/account';
import { useCurrencyStore } from '@/stores/currency';
import { defaultCurrencyId } from '@/constants/currency';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: null,
    countryId: null,
    account: null,
  }),
  getters: {
    isGuest() {
      return this.account?.isGuest;
    },
  },
  actions: {
    setAuthData(
      { token, countryId },
      setToCookie = true,
      { tokenCookie, countryIdCookie } = {},
    ) {
      this.token = token;
      this.countryId = countryId;

      if (setToCookie) {
        if (!tokenCookie) tokenCookie = useCookie('token');
        if (tokenCookie.value !== token) {
          tokenCookie.value = token;
        }

        if (!countryIdCookie) countryIdCookie = useCookie('countryId');
        if (countryIdCookie.value !== countryId) {
          countryIdCookie.value = countryId;
        }
      }
    },

    fetchGuestToken({ tokenCookie, countryIdCookie, currencyIdCookie } = {}) {
      const device = useDevice();
      const currencyStore = useCurrencyStore();
      return useRequest('/accounts/login', {
        query: { ...device.custom },
      }).then((data) => {
        const { token, countryId, currencyId } = accountLoginAdapter(data);

        currencyStore.setCurrencyId(
          currencyId ?? defaultCurrencyId,
          currencyIdCookie,
        );

        this.setAuthData({ token, countryId }, true, {
          tokenCookie,
          countryIdCookie,
        });

        return this.fetchAccount();
      });
    },

    requestLoginCode(email) {
      return useRequest('/accounts/code', {
        method: 'post',
        body: { email },
      });
    },
    login({ email, code }) {
      return useRequest('/accounts/login', {
        method: 'post',
        body: { email, code },
      }).then((data) => {
        const { token, countryId } = accountLoginAdapter(data);

        this.setAuthData({ token, countryId }, true);

        return this.fetchAccount();
      });
    },
    mergeGuestAndUser({ email, code }) {
      return useRequest('/accounts/merge', {
        method: 'post',
        body: { email, code },
      }).then((data) => {
        const { token, countryId } = accountLoginAdapter(data);

        this.setAuthData({ token, countryId }, true);

        return this.fetchAccount();
      });
    },

    fetchAccount() {
      return useRequest('/accounts').then((data) => {
        this.account = accountAdapter(data);
      });
    },
  },
});

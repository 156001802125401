<script setup>
import localStorageKeys from '@/constants/local-storage-keys';
import routes from '@/constants/routes';

const { t } = useI18n();

const isCookiesAccepted = useLocalStorage(
  localStorageKeys.COOKIES_ACCEPTED,
  false,
);

onMounted(() => {
  setTimeout(acceptCookies, 6000);
});

function acceptCookies() {
  try {
    gtagPushConsent('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
    });
  } catch (error) {
    console.log(error);
  }

  isCookiesAccepted.value = true;
}
</script>

<template>
  <div class="bundles-cookies">
    <div class="bundles-cookies__text">
      <span class="bundles-cookies__font bundles-cookies__font--text">
        {{ t('cookies.text') }}

        <a class="bundles-cookies__link" :href="routes.PRIVACY" target="_blank">
          {{ t('cookies.link') }}
        </a>
      </span>
    </div>

    <button
      class="bundles-cookies__button"
      type="button"
      @click="acceptCookies"
    >
      <span class="bundles-cookies__font bundles-cookies__font--button">
        {{ t('cookies.button') }}
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.bundles-cookies {
  display: flex;
  color: $color-white;
  background-color: rgba(#260057, 0.7);
  border-radius: em(9);

  &__font {
    display: block;

    &--text {
      font-size: em(12);
      font-weight: 400;
      line-height: math.div(14, 12);
    }

    &--button {
      font-size: em(16);
      font-weight: 400;
      line-height: math.div(24, 16);
    }
  }

  &__text {
    padding: em(11) em(12);
    border-right: 1px solid rgba($color-white, 0.2);
  }

  &__link {
    color: $color-second-1;
  }

  &__button {
    flex-shrink: 0;
    padding: em(13) em(20);
    transition: color $time-3;

    @include hover {
      color: $color-second-1;
    }
  }
}
</style>

import revive_payload_client_4sVQNw7RlN from "/app/dev/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/dev/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/dev/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/dev/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/dev/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/dev/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/dev/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/dev/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_QjNYEGgaKG from "/app/dev/node_modules/@vue-final-modal/nuxt/dist/runtime/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/app/dev/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/dev/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/dev/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/dev/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/dev/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ghbUAjaD3n from "/app/dev/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import analytics_client_mZYnNRQBH8 from "/app/dev/plugins/analytics.client.js";
import initial_data_lKRJEMtQLN from "/app/dev/plugins/initial-data.js";
import sentry_client_GoGQuZo4Et from "/app/dev/plugins/sentry.client.js";
import typograph_5DxQj8Q51f from "/app/dev/plugins/typograph.js";
import user_agent_custom_d99PIPsOfu from "/app/dev/plugins/user-agent-custom.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_QjNYEGgaKG,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_ghbUAjaD3n,
  analytics_client_mZYnNRQBH8,
  initial_data_lKRJEMtQLN,
  sentry_client_GoGQuZo4Et,
  typograph_5DxQj8Q51f,
  user_agent_custom_d99PIPsOfu
]
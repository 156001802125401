<script setup>
import { IMAGE_PREFIX } from '@/constants/images';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: undefined,
  },
  loading: {
    type: String,
    default: undefined,
  },
  alt: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
    default: undefined,
  },
});

const srcLocal = computed(() => {
  const { src } = props;

  if (src.startsWith('/images/')) return `${IMAGE_PREFIX}${src}`;

  if (props.size) getSizedImageSrc(src, props.size);
  else return src;
});

const srcSet = computed(() => {
  if (!props.size) return;

  return `${getSizedImageSrc(props.src, props.size)} 1x, ${getSizedImageSrc(props.src, Math.round(props.size * 2))} 2x`;
});
</script>

<template>
  <img
    :src="srcLocal"
    :srcset="srcSet"
    :alt="alt"
    :title="title"
    :loading="loading"
  />
</template>

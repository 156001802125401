import { DateTime } from 'luxon';

export default defineNuxtPlugin((nuxtApp) => {
  const parsedUserAgent = parseUserAgent(nuxtApp.$device.userAgent);
  nuxtApp.$device.custom = {
    device_model: combainPropsToString(
      parsedUserAgent.browser,
      parsedUserAgent.browserVersion,
    ),
    os_version: combainPropsToString(
      parsedUserAgent.os,
      parsedUserAgent.osVersion,
    ),
    device_brand: 'web',
    device_type:
      typeof nuxtApp?.$device?.isMobile === 'boolean'
        ? nuxtApp.$device.isMobile
          ? 'mobile'
          : 'desktop'
        : undefined,
    timezone: process.server
      ? undefined
      : (DateTime?.local?.()?.zoneName ?? undefined),
  };
});

function combainPropsToString(a, b) {
  if (!a && !b) return undefined;

  let str = '';
  str += a ?? '';
  str += b ? (a ? ' ' + b : b) : '';

  return str;
}

function parseUserAgent(userAgent) {
  const device = {};

  const osRegexes = [
    { name: 'Windows', regex: /Windows NT ([\d.]+)/ },
    { name: 'Mac OS', regex: /Mac OS X ([\d_]+)/ },
    { name: 'iOS', regex: /iPhone OS ([\d_]+)/ },
    { name: 'Android', regex: /Android ([\d.]+)/ },
    { name: 'Linux', regex: /Linux/ },
  ];

  for (const os of osRegexes) {
    const match = userAgent.match(os.regex);
    if (match) {
      device.os = os.name;
      device.osVersion = match[1]?.replace(/_/g, '.');
      break;
    }
  }

  const browserRegexes = [
    { name: 'Chrome', regex: /Chrome\/([\d.]+)/ },
    { name: 'Firefox', regex: /Firefox\/([\d.]+)/ },
    { name: 'Safari', regex: /Version\/([\d.]+) Safari\// },
    { name: 'Edge', regex: /Edg\/([\d.]+)/ },
    { name: 'Opera', regex: /OPR\/([\d.]+)/ },
  ];

  for (const browser of browserRegexes) {
    const match = userAgent.match(browser.regex);
    if (match) {
      device.browser = browser.name;
      device.browserVersion = match[1];
      break;
    }
  }

  return device;
}

import { IMAGE_PATH } from '@/constants/images';

const pageMetaAdapter = (data) => {
  const { image, robots } = data;

  return {
    id: data.id,
    title: data.title,
    description: data.description,
    keywords: data.keywords,
    canonical: data.canonical,
    image: image && {
      id: image.id,
      original: image.original,
    },
    robots: robots && {
      [robots.index ? 'index' : 'noindex']: true,
      [robots.follow ? 'follow' : 'nofollow']: true,
    },
    type: data.type,
  };
};

const adapters = {
  related: 'related',
  text: 'text',
  facts: 'facts',
  network_map: 'map',
  mobile_app_ad: 'mobile-app-ad',
  benefits: 'benefits',
  featured_pages_large: 'featured-pages',
  featured_pages_small: 'featured-pages',
  faq: 'faq',
  featured_pages_grid: 'featured-pages',
  top_links_list: 'links',
  featured_classes: 'classes',
  photos_slider: 'photos',
  luggage: 'luggage',
  featured_pages_small_alt: 'featured-pages',
  text_banner_large: 'text-banner-large',
  facts_large: 'facts',
  facts_banner: 'facts-banner',
  timetable: 'timetable',
  featured_pages: 'featured-pages',
  text_banner: 'text-banner',
  featured_facts: 'featured-facts',
  facts_slider: 'facts-slider',
  static_main_advantages: false,
  static_main_what_else: false,
  featured_pages_tabs: 'featured-pages-tabs',
};

export const pageAdapter = async ({ meta, data }) => {
  if (!data) return { meta: pageMetaAdapter(meta) };

  const { header, sections } = data;

  const sectionsData = await getSectionAdapters(sections, adapters);

  if (header) {
    const { form } = header;

    const headerSectionData = {
      type: 'header',
      data: {
        background: header.background || `${IMAGE_PATH}/seo/default/header.png`,
        title: header.title,
        form: form && {
          departureLocation: {
            id: form.departure_location?.id,
            name: form.departure_location?.name,
            country: form.departure_location?.country && {
              id: form.departure_location.country.id,
              name: form.departure_location.country.name,
              continentGroup: form.departure_location.country.continent_group,
            },
          },
          arrivalLocation: {
            id: form.arrival_location?.id,
            name: form.arrival_location?.name,
            country: form.arrival_location?.country && {
              id: form.arrival_location.country.id,
              name: form.arrival_location.country.name,
              continentGroup: form.arrival_location.country.continent_group,
            },
          },
        },
      },
    };
    sectionsData.unshift(headerSectionData);
  }

  return {
    meta: pageMetaAdapter(meta),
    sections: sectionsData,
  };
};

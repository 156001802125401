// Initial server data

// * renamed from initial-data.server.js due to plugin not start
// in pages with routeRules: 'ssr: false'

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const requests = [];

    // auth
    const authStore = useAuthStore();

    const tokenCookie = useCookie('token');
    const countryIdCookie = useCookie('countryId');
    const currencyIdCookie = useCookie('currencyId');
    const token = tokenCookie.value;
    const countryId = countryIdCookie.value;
    const currencyId = currencyIdCookie.value;

    if (token) {
      authStore.setAuthData({ token, countryId }, false);
    }

    if (!authStore.account) {
      if (!authStore.token) {
        requests.push(
          authStore.fetchGuestToken({
            tokenCookie,
            countryIdCookie,
            currencyIdCookie,
          }),
        );
      } else {
        requests.push(authStore.fetchAccount());
      }
    }

    // currency
    const currencyStore = useCurrencyStore();

    if (currencyId) {
      currencyStore.setCurrencyId(currencyId);
    }
    if (!currencyStore.currenciesFetched) {
      requests.push(currencyStore.fetchCurrencies());
    }

    await Promise.allSettled(requests);

    // // cart
    // const cartStore = useCartStore();
    //
    // if (!cartStore.cart) {
    //   requests.push(cartStore.fetchCart());
    // }
    //
    // await Promise.allSettled(requests);
  },
});

import { I18N_DEFAULT_LOCALE, I18N_LOCALES } from '@/constants/languages';

export const useSeo = ({
  title,
  description,
  image,
  // keywords,
  canonical,
  robots,
  locale,
}) => {
  const seoMeta = {
    title: title || '',
    ogTitle: title || '',

    description: description || '',
    ogDescription: description || '',

    ogImage: image || '',
    twitterTitle: title || '',
    twitterDescription: description || '',
    twitterImage: image || '',
    twitterCard: 'summary_large_image',

    // keywords: keywords || '',
    ogLocale: locale || '',
    ogUrl: canonical || '',
  };

  if (robots) {
    seoMeta.robots = robots;
  }

  useSeoMeta(seoMeta);

  // locales
  const url = useRequestURL();
  const pathWithoutLocale = url?.pathname
    ?.replace(new RegExp(`^/${locale?.()}`), '')
    .replace(/\/$/, '');

  const alternateLinks = [
    ...I18N_LOCALES.map((locale) => ({
      key: `alternate-${locale.iso}`,
      rel: 'alternate',
      hreflang: locale.iso,
      href:
        locale.code === I18N_DEFAULT_LOCALE
          ? `${url?.origin}${pathWithoutLocale}`
          : `${url?.origin}/${locale.code}${pathWithoutLocale}`,
    })),
    {
      key: `alternate-default`,
      rel: 'alternate',
      hreflang: 'x-default',
      href: `${url?.origin}${pathWithoutLocale}`,
    },
  ];

  useHead(() => ({
    link: [
      {
        rel: 'canonical',
        href: canonical,
      },
      ...alternateLinks,
    ],
  }));
};
